<script>
  import axios from "@/api/axios";
  import { mapState, mapGetters, mapActions } from "vuex";
  import { socket } from "@/socket";
  import NewsMixing from "./NewsMixing";
  export default {
    mixins: [NewsMixing],
    components: {
      NewsHeader: () => import("./NewsHeader.vue"),
      NewsCard: () => import("./NewsCard.vue"),
      Comments: () => import("@/components/news/comments/Comments.vue"),
      NewsFeatured: () => import("./NewsFeatured.vue"),
      OthersNews: () => import("./OthersNews.vue"),
      NewsSurvey: () => import("./NewsSurvey.vue"),
      Carrousel: () => import("../../../components/Competitions/CompetitionCarrousel.vue"),
    },
    data() {
      return {
        news: [],
        isLoading: false,
        page: 1,
        cpage: 1,
        passMore: false,
        totalNews: 0,
        showComments: {},
        toggleKey: 0,
        currentNews: {},
        isPaginate: false,
        best: [],
        awards: [],
        reload: 0,
        competitionActive: false,
        showCompetition: false,
        canDrag: false,
      };
    },
    computed: {
      ...mapState("user", {
        user: (state) => state.profile,
      }),
      ...mapGetters("competitions", ["getCompetitions", "getCompetition", "getRanking"]),
      ...mapGetters("news", ["getNews", "getError", "getHasMore"]),
      socketEvents() {
        const socketReady = this.$store.getters["connectedUsers/getSocketState"];
        if (socketReady)
          socket.on("newComment", ({ newId, toResponse, toUserResponse }) => {
            if (toUserResponse && this.user._id === toResponse) this.$store.commit("showBanner", { message: `${toUserResponse} respondió un comentario tuyo de una noticia.` });
            this.$store.commit("news/handleComments", { newId, toResponse, userId: this.user._id });
          });
      },
      filteredNews() {
        const newsCount = this.$screen.width > 1730 ? 9 : 10;
        return this.getNews.slice(0, newsCount);
      },
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionsDb", "getCompetitionById", "getRankingDb"]),
      async dragAndDrop() {
        await this.sleep(500);
        let inicio, final, updateItemTwo, updateItemOne, temp;
        let dragSrcEl = null;
        let items = document.querySelectorAll("#children,#first-childTest");
        for (let item of items) {
          item.addEventListener("dragstart", (e) => {
            e.target.classList.add("dragging");
            dragSrcEl = e;
            inicio = e.target.attributes[2].nodeValue;
          });

          item.addEventListener("dragover", (e) => {
            if (e.preventDefault) {
              e.preventDefault();
            }
            return false;
          });

          item.addEventListener("dragenter", (e) => {
            const targetElement = e.target.closest("#children, #first-childTest");
            targetElement.classList.add("over");
          });

          item.addEventListener("dragleave", (e) => {
            const targetElement = e.target.closest("#children, #first-childTest");
            targetElement.classList.remove("over");
          });

          item.addEventListener("dragend", (e) => {
            e.target.classList.remove("dragging", "over");
          });
          item.addEventListener("drop", async (e) => {
            e.stopPropagation();
            if (dragSrcEl !== e) {
              const targetElement = e.target.closest("#children, #first-childTest");
              targetElement.classList.remove("over");
              final = targetElement.getAttribute("index");
              temp = this.getNews[final].index;
              updateItemTwo = this.getNews[final];
              updateItemTwo.index = this.getNews[inicio].index;
              updateItemOne = this.getNews[inicio];
              updateItemOne.index = temp;
              const dataSend = [updateItemOne, updateItemTwo].map((item) => {
                return { _id: item._id, index: item.index };
              });
              await axios.post(`/news/updateIdx`, dataSend);
              this.$store.dispatch("news/fetchNews");
            }
            return false;
          });
        }
      },
      handleComments({ id, open }) {
        open ? document.body.classList.add("onmodal") : document.body.classList.remove("onmodal");
        this.showComments = { ...this.showComments, [id]: open };
        if (!open) this.$store.commit("news/closeComments", { id });
      },
      isPrivate: function({ forUser }) {
        if (forUser && forUser[0]) {
          return true;
        }
      },
      cutName(file) {
        if (file) {
          return file
            .slice(99)
            .split("%20")
            .join(" ");
        }
      },
      linkText(itera) {
        let urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
        return itera.replace(urlRegex, function(url) {
          return `<a class="newsList__text--link" href="${url.toUpperCase().startsWith("W") ? "http://" : ""}${url}"  target="_blank"> ${url}</a>`;
        });
      },
      validFileIcon(file, action) {
        if (file) {
          let type = file.slice(-5);
          if (type.includes("pdf")) {
            if (action === "icon") {
              return "file_pdf";
            } else {
              return "files--pdf";
            }
          }
          if (type.includes("docx")) {
            if (action === "icon") {
              return "file_word";
            } else {
              return "files--word";
            }
          }
          if (type.includes("pptx")) {
            if (action === "icon") {
              return "file_power_point";
            } else {
              return "files--powerPoint";
            }
          }
          if (type.includes("xlsx")) {
            if (action === "icon") {
              return "file_excel";
            } else {
              return "files--excel";
            }
          }
        }
        return false;
      },
      async showMore(cpage) {
        this.isLoading = true;
        this.page = cpage;
        await this.paginationNews(this.page);
        this.isLoading = false;
      },
      goCompetition() {
        this.$router.push("/competition");
      },
      async paginationNews(page) {
        const res = await this.$api.get(`/news?page=${page}`);
        this.totalNews = res.data.total;
        this.$store.commit("news/pushNews", res.data.news);
        this.$store.commit("news/setHasMore", res.data.hasMore);
      },
      isEdit(news) {
        this.$router.push({ name: "formNews", query: { new: news } });
      },
      async enableNews(id) {
        this.$alerts
          .dataSendConfirm({
            title: "Eliminar",
            message: "¿Está seguro? Se eliminará la noticia",
            buttonTextYes: "Si, eliminar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$alerts.dataSending();
              const res = await this.$api.post(`/news/enable`, { id });
              if (res.data.success) {
                this.$alerts.dataSendSuccess({ message: res.data.success });
                const indexNews = this.$store.state.news.news.findIndex((item) => item._id === id);
                this.$store.state.news.news.splice(indexNews, 1);
              } else {
                this.$alerts.dataSendError({ message: res.data.notSuccess || res.data.error });
              }
            }
          });
      },
      validateCompetitionView() {
        this.showCompetition = this.getCompetition?.policiesView?.users.includes(this.$user._id);
      },
      async createRanking() {
        const { _id, totalPodium } = this.getCompetition;
        this.validateCompetitionView();
        const { ranking, awards, positionByRange } = await this.getRankingDb({ contestId: _id, query: `page=0` });

        this.best = ranking.slice(0, totalPodium);
        this.awards = awards.slice(0, totalPodium);
        this.statusRange = positionByRange;
      },
      userCanDragAndDrop() {
        if (this.$profile.role === "superadmin") {
          this.canDrag = this.$screen.width > 1024;
          this.$nextTick(this.dragAndDrop);
        }
      },

      async getActiveCompetition() {
        const findActiveCompetition = this.getCompetitions.find(({ status }) => status === "active");

        if (findActiveCompetition) {
          await this.getCompetitionById({ _id: findActiveCompetition._id });
          await this.createRanking();
          this.competitionActive = true;
        }
      },
    },
    watch: {
      best(val) {
        this.reload++;
      },
    },
    mounted: async function() {
      await this.$store.dispatch("news/fetchNews");
      this.userCanDragAndDrop();
      await this.getCompetitionsDb(`?status=active&show=news`);
      await this.getActiveCompetition();
    },
    beforeDestroy() {
      socket.off("newComment");
    },
  };
</script>

<template>
  <div class="newsList" v-if="user" v-bind="socketEvents">
    <NewsHeader />
    <div class="newsList__container" @click="goCompetition" v-if="competitionActive" :key="reload">
      <div class="newsList__subContainer">
        <div>
          <h1 class="newsList__title">{{ getCompetition.name }}</h1>
        </div>
        <div class="newsList__goCompetition" @click="goCompetition">
          <button v-if="showCompetition" class="newsList__button">
            {{ `Ver ${getCompetition.showTop ? `top ${getCompetition.showTop}` : ""} ranking` }}
            <span v-for="i in 4" :key="i" class="newsList__buttonSpan"></span>
          </button>
        </div>
      </div>
      <div class="newsList__carousel">
        <Carrousel :best="best" :awards="awards" :subtitle="getCompetition.description" :modal="false" :statusRange="statusRange" />
      </div>
    </div>
    <div class="main-news news-wall" @click="dragAndDrop()">
      <NewsSurvey />
      <NewsCard
        v-for="(news, index) in filteredNews"
        :key="news.index"
        :block="'mainNews'"
        :index="index"
        :news="news"
        :draggable="canDrag"
        :id="index === 0 ? 'first-childTest' : 'children'"
      ></NewsCard>
    </div>
    <NewsFeatured />
    <OthersNews />

    <div v-if="isPaginate && !isLoading && getHasMore">
      <button class="click-on-bottom" @click="showMore((cpage += 1))">Ver más</button>
    </div>
    <div class="spinner" v-if="isLoading"></div>
  </div>
</template>

<style lang="scss" scoped>
  .main-news {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 57px;
    .dragging {
      opacity: 0.1;
    }
    .over {
      border: 3px dotted $primary-color;
      border-radius: $mradius;
    }
  }
  .bestseller {
    position: relative;
    &::after {
      position: absolute;
      visibility: hidden;
      top: 26px;
      right: -25px;
      text-align: center;
      content: "Destacada";
      font-size: 11px;
      letter-spacing: 0.8px;
      font-weight: bold;
      color: $white;
      text-transform: uppercase;
      line-height: 20px;
      transform: rotate(45deg);
      width: 120px;
      background: $primary_color;
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    }
    &.section-new {
      &::after {
        visibility: visible;
      }
    }
  }
  .section-new {
    position: relative;
  }
  .newsList {
    width: 100%;
    margin-top: 60px;
    &__container {
      background: #fff;
      max-width: 1880px;
      width: 100%;
      border: 1px solid lightgrey;
      border-radius: 12px;
      margin: 0 auto;
      padding: 20px;
      height: 100%;
      box-shadow: $dshadow;
      margin-top: 10px;
      cursor: pointer;
    }
    &__subContainer {
      @include Flex(row, space-between, flex-start);
    }
    &__carousel {
      margin-top: -65px;
    }
    &__goCompetition {
      @include Flex(row);
      min-height: 50px;
      margin-top: 35px;
      transform: translate(-50%, -50%);
      height: auto;
    }
    &__button {
      width: 250px;
      height: 50px;
      background: linear-gradient(to left top, #a90606 50%, #bd0909 50%);
      border-style: none;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      outline: none;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      transition: all 0.5s;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
      margin-left: -250px !important;
      border-radius: 12px;

      &:hover {
        transition: all 0.5s;
        transform: rotate(-3deg) scale(1.1);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
      }
      &:hover .newsList__buttonSpan {
        animation-play-state: paused;
      }
    }
    &__buttonSpan {
      position: absolute;
      display: block;
    }
    &__buttonSpan:nth-child(1) {
      height: 3px;
      width: 200px;
      top: 0px;
      left: -200px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), #f6e58d);
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
      animation: span1 2s linear infinite;
      animation-delay: 1s;
    }
    &__buttonSpan:nth-child(2) {
      height: 70px;
      width: 3px;
      top: -70px;
      right: 0px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #f6e58d);
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
      animation: span2 2s linear infinite;
      animation-delay: 2s;
    }

    &__buttonSpan:nth-child(3) {
      height: 3px;
      width: 200px;
      right: -200px;
      bottom: 0px;
      background: linear-gradient(to left, rgba(0, 0, 0, 0), #f6e58d);
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      animation: span3 2s linear infinite;
      animation-delay: 3s;
    }

    &__buttonSpan:nth-child(4) {
      height: 70px;
      width: 3px;
      bottom: -70px;
      left: 0px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0), #f6e58d);
      border-top-right-radius: 1px;
      border-top-left-radius: 1px;
      animation: span4 2s linear infinite;
      animation-delay: 4s;
    }
    .link {
      display: flex;
      align-items: center;
      font-size: 175%;
      margin-left: 20px;
      margin-top: -15px;
      &:hover {
        text-decoration: none;
        color: $lipstick;
      }
    }
    &__thumbnail {
      background-size: cover;
      background-position: top 10% center;
      position: relative;
      transition: all 0.25s;
    }
    &__buttonPlay {
      font-size: 500%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 60px;
      opacity: 0.3;
      width: 60px;
      border-radius: 50%;
      left: 45%;
      bottom: 45%;
      cursor: pointer;
      background: white;
      z-index: 2;
    }
    &__commentIco {
      &--color {
        color: $primary_color;
      }
    }
    ol,
    ul {
      margin-left: 13px;
    }
    &__lines {
      height: 1px;
      background-color: $alto;
    }
    .fa-clock {
      color: gray;
    }
    .files {
      font-size: 400%;
      cursor: pointer;
      &--file {
        color: $chicago;
      }
      &--pdf {
        color: $lipstick;
      }
      &--word {
        color: $matisse;
      }
      &--powerPoint {
        color: $tree_Poppy;
      }
      &--excel {
        color: $excel;
      }
    }
    font-size: 16px;
    font-family: $first_font;
    &__header {
      display: block;
      padding: $mpadding;
      max-width: $max-width;
      margin: 0 auto;
      text-align: center;
      color: $lipstick;
      font-size: 2.5em;
      font-weight: bold;
      font-family: $sec_font;
    }
    &__title {
      padding: 0 40px $mpadding/2 0;
      font-size: 2em;
      color: $lipstick;
      font-family: $sec_font;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__date {
      margin-top: $mpadding/5;
      font-size: 12px;
    }
    &__line {
      content: " ";
      background-color: $primary-color;
      border: $primary-color solid 2px;
      width: 180px;
      position: relative;
      left: -15px;
    }
    &__subtitle {
      font-size: 1.6em;
      color: $chicago;
      font-weight: bold;
      font-family: $sec_font;
    }
    &__text {
      color: $black;
      padding: $mpadding 0;
      &--link {
        color: #2b85cd;
        &:hover {
          color: $black;
        }
      }
    }
    &__document,
    &__document {
      height: 170px;
    }
    &__iDownload {
      min-height: 80px;
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $lipstick;
    }
    &__download {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
    &__contentBtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
    }
    & &__toggle {
      @include Row();
      .toggle-switch {
        order: 2;
      }
      .toggle-label {
        order: 1;
        margin-right: 5px;
        top: -1px;
      }
      .toggle-checkbox + .toggle-switch {
        background-color: $primary_color;
      }
      .toggle-checkbox:checked + .toggle-switch {
        background-color: $alto;
      }
    }
    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      margin: $mpadding/2 5px;
      padding: $mpadding/2;
      border-radius: 2pc;
      background: $lipstick;
      border: none;
      color: $white;
      &--delete {
        background: $chicago;
      }
      &:hover {
        opacity: $opacity-button;
        outline: none;
      }
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
    }
    &__realTime {
      display: flex;
      align-items: center;
      padding: $mpadding 0;
    }
    &__comments {
      margin-left: 20px;
    }
    &__commentsBtn {
      user-select: none;
      cursor: pointer;
      p {
        font-size: 14px;
        padding: 0 $mpadding / 2;
      }
    }
    @media (min-width: $mobile_width) {
      &__document {
        height: 370px;
      }
      &__toggle {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      .main-news {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      #first-childTest {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    @media (min-width: 1024px) and (max-width: 1730px) {
      .main-news {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
      #first-childTest {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
    @media (min-width: 1731px) {
      .main-news {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
      #first-childTest {
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
    @media (max-width: 645px) {
      &__carousel {
        margin-top: 0px;
      }
      &__subContainer {
        display: flex;
        flex-direction: column;
      }
      &__goCompetition {
        margin-left: 83%;
        margin-bottom: -30px;
      }
    }

    @media (max-width: 330px) {
      &__subContainer {
        display: flex;
        flex-direction: column;
      }
      &__goCompetition {
        margin-left: 98%;
        margin-bottom: -30px;
      }
    }
    @keyframes span1 {
      0% {
        left: -200px;
      }
      100% {
        left: 200px;
      }
    }
    @keyframes span2 {
      0% {
        top: -70px;
      }
      100% {
        top: 70px;
      }
    }
    @keyframes span3 {
      0% {
        right: -200px;
      }
      100% {
        right: 200px;
      }
    }
    @keyframes span4 {
      0% {
        bottom: -70px;
      }
      100% {
        bottom: 70px;
      }
    }
  }
</style>
